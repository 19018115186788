import  { useEffect } from "react";
import { FaCheckCircle, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";


const AboutUs = () => {


  return (
    <div className="bg-gray-50 py-16 px-6 md:px-20 lg:px-40 text-gray-800 mt-[8rem]" id="contactus">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-5xl font-extrabold text-red-600 drop-shadow-xl">
          About Arabian<span className="text-gray-500">kings</span>
        </h1>
        <p className="text-xl mt-4 font-light text-gray-700">
          Your Gateway to Unforgettable Experiences in UAE
        </p>
      </div>

      <div className="bg-white shadow-xl rounded-xl p-12 space-y-12">
        {/* Welcome Message */}
        <p className="text-lg leading-relaxed text-gray-700">
          Welcome to <span className="font-bold text-red-600">Arabiankings Travel and Tourism</span>, your premier travel partner in Dubai, UAE. We specialize in delivering tailored travel experiences, from luxurious desert safaris to seamless visa assistance. Let us transform your journey into a once-in-a-lifetime adventure.
        </p>

        {/* Our Story */}
        <div className="bg-gray-100 p-8 rounded-xl shadow-md">
          <h2 className="text-4xl font-bold text-red-600">Our Story</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            With a passion for travel and hospitality, Arabiankings was founded to redefine tourism in the UAE. Over the years, we’ve curated countless unforgettable experiences for travelers worldwide, ensuring quality, comfort, and personalized services.
          </p>
        </div>

        {/* What We Offer */}
        <div className="bg-gray-100 p-8 rounded-xl shadow-md">
          <h2 className="text-4xl font-bold text-red-600">What We Offer</h2>
          <ul className="list-none mt-4 text-lg space-y-4">
            {[
              "Customized Tour Packages",
              "Hassle-free Visa Assistance",
              "Luxury Desert Safaris",
              "Premium Yacht & Cruise Tours",
              "Hotel & Transport Services"
            ].map((item, index) => (
              <li key={index} className="flex items-center">
                <FaCheckCircle className="text-red-600 mr-3" /> {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Why Choose Us */}
        <div className="bg-gray-100 p-8 rounded-xl shadow-md">
          <h2 className="text-4xl font-bold text-red-600">Why Choose Us?</h2>
          <ul className="list-none mt-4 text-lg space-y-4">
            {[
              "Personalized itineraries",
              "Expert local guides",
              "24/7 customer support",
              "Transparent pricing"
            ].map((item, index) => (
              <li key={index} className="flex items-center">
                <FaCheckCircle className="text-red-600 mr-3" /> {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Vision & Mission */}
        <div className="bg-gray-100 p-8 rounded-xl shadow-md">
          <h2 className="text-4xl font-bold text-red-600">Our Vision & Mission</h2>
          <p className="mt-4 text-lg text-gray-700">
            Our vision is to become the most trusted travel agency in the UAE, introducing travelers to the beauty of Dubai through curated experiences. We uphold values of trust, integrity, and excellence to ensure unparalleled customer satisfaction.
          </p>
        </div>

        {/* Contact Section */}
       
      </div>
    </div>
  );
};

export default AboutUs;
