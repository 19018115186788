import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for React Router

const PriceCard = ({
  image,
  title,
  reviews,
  rating,
  originalPrice,
  discountedPrice,
  isRecommended,
  category,
  nights,
  buttonLink, // Dynamically received link
  buttonText = "View Details", // Default button text
}) => {
  const navigate = useNavigate(); // React Router navigation

  return (
    <div className="border rounded-lg shadow-md p-4 flex flex-col gap-4 relative">
      {isRecommended && (
        <div className="absolute top-2 left-2 bg-red-500 text-white rounded-full px-3 py-1 text-xs font-semibold">
          Recommended
        </div>
      )}
      <img src={image} alt={title} className="rounded-lg h-48 object-cover" />
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold text-lg">{title}</h3>
        <p className="text-sm text-gray-500">{reviews} Reviews</p>
        <div className="flex items-center">
          <div className="text-red-400 text-sm font-semibold">
            {"⭐".repeat(Math.floor(rating))}
          </div>
          <span className="ml-2 text-gray-500 text-sm">({rating})</span>
        </div>
        <div className="flex justify-between text-sm text-gray-500">
          <p>Category: {category}</p>
          <p>{nights} Nights</p>
        </div>
        <div className="text-right">
          {originalPrice && (
            <p className="text-sm line-through text-gray-400">
              AED {originalPrice}
            </p>
          )}
          <p className="text-lg font-bold text-gray-800">Price : {discountedPrice}</p>
        </div>
        {/* Button appears only if buttonLink exists */}
        {buttonLink && (
          <button
            onClick={() => navigate(buttonLink)}
            className="mt-3 bg-[#fb4748] text-white text-sm font-semibold px-4 py-2 rounded-lg hover:bg-[#fb4748] transition"
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default PriceCard;
