import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import UpIcon from "../../assets/icons/UpArrow";
import DownArrow from "../../assets/icons/DownArrow";

const DefaultHeader = () => {
    const logo = "/assets/img/logo-new.png";
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [activeMobileDropdownIndex, setActiveMobileDropdownIndex] = useState(null);

    const menuRef = useRef(null);

    // Handle Scroll Effect
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close dropdown if clicked outside of the menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveDropdownIndex(null);
            }
        };

        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // Menu Items Configuration
    const menuItems = [
        { label: "Home", path: "/" },
        { label: "About Us", path: "/about" },
        {
            label: "Services", path: "/services", hasDropdown: true, children: [
                { label: "UAE Packages", path: "/services/service1" },
                { label: "Excursions", path: "/services/service2" },
                { label: "Limousine", path: "/services/service3" },
                { label: "VISA", path: "/services/service4" },
            ]
        },
        {
            label: "Can I Help You",
            path: "/help",
            hasDropdown: true,
            children: [
                { label: "FAQ", path: "/help/faq" },
                // { label: "Support", path: "/help/support" },
                { label: "Chat", path: "/help/chat" },
            ],
        },
        { label: "Contact us", path: "/contact" },
    ];

    return (
        <header
            className={`fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${isScrolled ? "bg-white shadow-lg" : "bg-white shadow-lg"
                }`}
        >
            <div className="container mx-auto flex justify-between items-center " ref={menuRef}>
                {/* Logo */}
                <Link to="/" className="flex items-center">
                    <img
                        src={logo}
                        alt="Company Logo"
                        className="md:w-32 md:h-32 w-32 h-32 object-contain"
                    />
                </Link>

                {/* Desktop Navigation */}
                <nav className="hidden lg:flex gap-x-16">
                    {menuItems.map((item, index) => (
                        <div key={index} className="relative">
                            {!item.hasDropdown ? (
                                <Link
                                    to={item.path}
                                    className={`hover:text-gray-600 ${isScrolled ? "text-black font-bold lg:text-lg" : "text-black font-bold lg:text-lg"
                                        }`}
                                >
                                    {item.label}
                                </Link>
                            ) : (
                                <span
                                    className={`cursor-pointer hover:text-gray-600 flex items-center ${isScrolled ? "text-black font-bold lg:text-lg" : "text-black font-bold lg:text-lg"
                                        }`}
                                    onClick={() =>
                                        setActiveDropdownIndex((prevIndex) => (prevIndex === index ? null : index))
                                    }
                                >
                                    {item.label}
                                    <span className="ml-2 flex items-center">
                                        {activeDropdownIndex === index ? (
                                            <UpIcon height="21px" width="21px" />
                                        ) : (
                                            <DownArrow height="21px" width="21px" />
                                        )}
                                    </span>
                                </span>
                            )}
                            {item.hasDropdown && activeDropdownIndex === index && (
                                <div
                                    className="absolute top-full left-0 mt-2 bg-white shadow-lg rounded-lg text-black w-48"
                                    onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside
                                >
                                    <ul>
                                        {item.children.map((child, childIndex) => (
                                            <li key={childIndex} className="p-2 hover:bg-gray-200">
                                                <Link
                                                    to={child.path}
                                                    className="block w-full h-full"
                                                    onClick={() => setActiveDropdownIndex(null)} // Close dropdown on link click
                                                >
                                                    {child.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    ))}
                </nav>


                {/* Contact Us Button */}
                <button
                    className="hidden lg:inline-block bg-gradient-to-r from-red-500 to-gray-700 text-white lg:px-10 lg:py-3 rounded-xl font-bold shadow-md hover:shadow-lg hover:scale-105 transition-transform"
                    aria-label="Contact Us"
                >
                    Login
                </button>

                {/* Mobile Menu Button */}
                <button
                    className={`lg:hidden text-2xl focus:outline-none ${isScrolled ? "text-black" : "text-white"
                        }`}
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    ☰
                </button>
            </div>

            {/* Mobile Menu */}
            <div
                className={`fixed inset-0 bg-gray-800 bg-opacity-90 z-40 flex flex-col py-5 px-10 text-white transition-transform duration-500 ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
                    }`}
            >
                <button
                    className="absolute top-4 right-4 text-2xl focus:outline-none"
                    onClick={() => setIsMobileMenuOpen(false)}
                >
                    ✕
                </button>

                <nav className="flex flex-col space-y-6 text-xl">
                    {menuItems.map((item, index) => (
                        <div key={index} className="relative">
                            {!item.hasDropdown ? (
                                <Link
                                    to={item.path}
                                    className="hover:text-gray-300"
                                    onClick={() => setIsMobileMenuOpen(false)}
                                >
                                    {item.label}
                                </Link>
                            ) : (
                                <div>
                                    <span
                                        className="cursor-pointer hover:text-gray-300"
                                        onClick={() =>
                                            setActiveMobileDropdownIndex(
                                                activeMobileDropdownIndex === index ? null : index
                                            )
                                        }
                                    >
                                        {item.label}{" "}
                                        {activeMobileDropdownIndex === index ? "▲" : "▼"}
                                    </span>
                                    {activeMobileDropdownIndex === index && (
                                        <div className="flex flex-col space-y-2 mt-4">
                                            {item.children.map((child, childIndex) => (
                                                <Link
                                                    key={childIndex}
                                                    to={child.path}
                                                    className="text-gray-300 hover:text-gray-500"
                                                    onClick={() => setIsMobileMenuOpen(false)}
                                                >
                                                    {child.label}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default DefaultHeader;
