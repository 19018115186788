const fourNight = "/assets/img/night-tour.jpg";

const DhowCruise = "/assets/img/dhow.jpg"
const DesertSafari = "/assets/img/dubai/desert.webp"
const BurjKhalifa = "/assets/img/dubai/burj.webp"
const AtlanticAdvanture = "/assets/img/dubai/atlantic.webp"
const airDrop = "/assets/img/dubai/drop.png"


const FiveNight = "/assets/img/five-nights.jpg"
const arraival = "/assets/img/araival.jpg"
const laPearl = "/assets/img/la-pearl.png"
const Dshopping = "/assets/img/morning-shopping.png"
const DesMus = "/assets/img/des-mus.png"
const resPark = "/assets/img/res-park.png"


const SuperSaver = "/assets/img/super-saver.png"
const YsLand = "/assets/img/ysland.png"
const Mosque = "/assets/img/mosque.jpg"
const BurjGarden = "/assets/img/burj-garden.jpg"

const HoneymoonBliss = "/assets/img/honeymoon.png"
const Lim = "/assets/img/limo.png"
const Yatch = "/assets/img/yatch.jpg"

const BurjFoundation = "/assets/img/BurjFoundation.png"




const tripDetails = {
    1: {
        title: "Dubai 4-Night Tour Package",
        inclusionTitle: "Inclusions",
        heroImage: fourNight,
        inclusions: [
            "4 Nights’ accommodation in Dubai Hotel",
            "Daily Breakfast",
            "Dhow Cruise Marina dinner with transfers",
            "Desert Safari with BBQ dinner and transfers",
            "Dubai City Tour with transfers",
            "Burj Khalifa Non-prime hour tickets with transfers",
            "Atlantis Aquaventure Waterpark Ticket with transfers",
            "30 days UAE tourist Visa",
            "Return Dubai Airport transfers on PVT basis",
            "All tours and transfers on coach basis",
            "All taxes included except Tourism Dirham"
        ],
        itinerary: [
            {
                image: DhowCruise,
                day: "Day 1",
                title: "Dubai Arrival and Dhow Cruise at Dubai Marina",
                description: "A relaxed transfer from Dubai International Airport to your pre-arranged accommodations ensures a perfect start to your Arabian holiday. Check into your room, freshen up, and later join for a classic dhow cruise along the stylish Dubai Marina in the evening. The approximately two-hour cruise includes buffet dining with live shows like Tanura, and allows you to see the glamorous cityscape from a striking standpoint on the water."
            },
            {
                image: DesertSafari,
                day: "Day 2",
                title: "Desert Safari",
                description: "Enjoy the day at your pace until you embark on an exhilarating evening desert safari. Experience the rush of a lifetime on a 4x4 dune bash session, and add diverse cultural experiences like camel riding, Shisha smoking, and falconry to your desert adventure. Round out this off-road tour with a lavish BBQ dinner and traditional performances, including Tanura and belly dance."
            },
            {
                image: BurjKhalifa,
                day: "Day 3",
                title: "Dubai City Tour & Burj Khalifa",
                description: "Kick off the day as our representative picks you up for a half-day sightseeing tour. It takes you to the city’s celebrated and lesser-known sights, including Downtown Dubai, Dubai Marina, Al Bastakiya Quarter, Burj Al Arab, Atlantis, The Palm, Jumeirah Mosque, and Jumeirah Beach. Following this, you will enter the world’s tallest building with your Burj Khalifa admission tickets. Watch a multimedia show on Dubai’s journey from a fishing settlement to a global city before you board one of the fastest elevators that whisk you to the observation deck on its 124th level. See the whole of Dubai, covering the city skyline, desert, and ocean, as you stand here."
            },
            {
                image: AtlanticAdvanture,
                day: "Day 4",
                title: "Atlantis Aquaventure Waterpark",
                description: "Dedicate today to treating yourself to a day of unmatched water amusement and thrill at Aquaventure Waterpark within Atlantis, The Palm Resort, on the legendary Palm Jumeirah. Whether looking for the most heart-racing rides, family-and-kid-friendly attractions, or mellower experiences, it promises an exciting day with something for everyone!"
            },
            {
                image: airDrop,
                day: "Day 5",
                title: "Airport Drop-Off",
                description: "End your Dubai holidays on a hassle-free note as you check out of the hotel and enjoy a comfortable ride to the airport with our prompt departure transfers."
            }
        ]
    },
    2: {
        title: "Dubai 5-Night Tour Package",
        inclusionTitle: "Inclusions",
        heroImage: FiveNight,
        inclusions: [
            "5 Nights’ accommodation in Dubai Hotel",
            "Daily Breakfast",
            "Dubai City Tour with transfers",
            "La Perle Show with tickets and transfers",
            "Half-day Morning Shopping Tour",
            "Museum of the Future tickets with transfers",
            "Desert Safari with BBQ dinner and transfers",
            "Dubai Parks and Resorts entry with transfers",
            "Return Dubai Airport transfers on PVT basis",
            "All tours and transfers on coach basis",
            "All taxes included except Tourism Dirham"
        ],
        itinerary: [
            {
                image: arraival,
                day: "Day 1",
                title: "Dubai Arrival",
                description: "Arrival at Dubai International Airport. Transfer to the hotel."
            },
            {
                image: laPearl,
                day: "Day 2",
                title: "Dubai City Tour & La Perle Show",
                description: "City sightseeing of Dubai, covering Dubai Creek, Jumeirah Mosque, Burj Al Arab, Atlantis the Palm, Burj Khalifa, Jumeirah Beach, Madinat Jumeirah, and Sheikh Zayed Road. The tour lasts approximately 4 hours. Later in the evening, experience La Perle by Dragone, Dubai’s first permanent show, with spectacular performances."
            },
            {
                image: Dshopping,
                day: "Day 3",
                title: "Half Day Morning Shopping Tour",
                description: "Enjoy a morning shopping tour, exploring Dubai’s malls, luxury boutiques, and Emirati souks for incredible bargains. The evening is free for leisure."
            },
            {
                image: DesMus,
                day: "Day 4",
                title: "Museum of the Future & Desert Safari",
                description: "Visit the iconic Museum of the Future, followed by an exhilarating Desert Safari. Enjoy dune bashing, camel riding, sunset photography, Arabic coffee and dates, henna painting, traditional performances like Tanura and belly dancing, and a BBQ buffet dinner at a desert camp."
            },
            {
                image: resPark,
                day: "Day 5",
                title: "Dubai Parks and Resorts",
                description: "Spend a fun-filled day at Dubai Parks and Resorts. Visit the Real Madrid Experience Zone, enjoy interactive exhibits, sports challenges, and themed attractions. Have lunch at one of the park’s restaurants, and explore more before returning to the hotel."
            },
            {
                image: airDrop,
                day: "Day 6",
                title: "Airport Drop-Off",
                description: "Enjoy a hassle-free departure as you check out from the hotel and transfer to Dubai International Airport for your return flight."
            }
        ]
    },
    3: {
        title: "Super Saver Dubai & Abu Dhabi Tour Package",
        inclusionTitle: "Inclusions",
        heroImage: SuperSaver,
        inclusions: [
            "Accommodation in Dubai and Abu Dhabi",
            "Daily Breakfast",
            "Dhow Cruise Marina with dinner",
            "Dubai City Tour",
            "Desert Safari with BBQ dinner",
            "Burj Khalifa tickets",
            "Dubai Mall & Fountain Show",
            "Dubai Miracle Garden entry",
            "Abu Dhabi City Tour & Grand Mosque",
            "Yas Island Theme Park entry",
            "Return Airport Transfers",
            "All taxes included except Tourism Dirham"
        ],
        itinerary: [
            {
                image: DhowCruise,
                day: "Day 1",
                title: "Airport Pickup and Dhow Cruise Marina",
                description: "Following your arrival at Dubai International Airport, Meet your driver at Dubai International Airport for a smooth transfer to your accommodation. Relax before enjoying a leisurely dhow cruise across Dubai Marina, complete with buffet dining and traditional entertainment like a Tanura show."
            },
            {
                image: DesertSafari,
                day: "Day 2",
                title: "Dubai City Tour and Desert Safari",
                description: "What awaits you today is a fully guided city tour, where you will visit Dubai’s star attractions, featuring the perfect blend of contemporary and classic gems. Discover the Al Fahidi Historical Neighbourhood, pass through Jumeirah Beach, and enjoy photo opportunities at Jumeirah Mosque, Atlantis The Palm, and Palm Jumeirah, to name a few. After your half-day city tour, embark on a desert safari, revealing the city’s raw and unfiltered desert charm. Experience the thrill of 4x4 dune bashing, try camel riding, smoke Shisha, adorn your hands with traditional henna designs, watch belly dancing and a Tanura performance, and round out your trip with a delightful BBQ dinner."
            },

            {
                image: BurjGarden,
                day: "Day 3",
                title: "Burj Khalifa, Dubai Mall, Dubai Fountain Show & Dubai Miracle Garden",
                description: "Today, see some of Dubai’s record-breaking attractions as you tour Dubai Downtown’s marvels and Dubai Miracle Garden. One of the fastest elevator rides transports you to ‘At the Top’ observatory inside Burj Khalifa, the world’s tallest building. Continue your Downtown exploration to the Dubai Mall, the mega hub of leisure, dining, and shopping spanning 12 million square feet. Before you leave, watch the captivating choreographed water show on Burj Lake. At Dubai Miracle Garden, prepare to witness over 150 million flowers arranged in almost every size, shape, and design you could imagine."
            },

            {
                image: Mosque,
                day: "Day 4",
                title: "Abu Dhabi City Tour & Grand Mosque Check-in to Abu Dhabi Hotel",
                description:
                    "Bid farewell to Dubai after hotel check-out and then travel straight to Abu Dhabi. See the capital city's top claims to fame on your guided excursion, including Sheikh Zayed Grand Mosque, Abu Dhabi Corniche, Yas Island, Heritage Village, and Emirates Palace Hotel. Once the sightseeing tour ends, unwind in the comfort of your pre-arranged Abu Dhabi accommodation."


            },
            {
                image: YsLand,
                day: "Day 5",
                title: "Visit Yas Island Theme Park",
                description: "Today, you will enjoy a complimentary entry into one of your favorite theme parks on Yas Island. Whether you choose the Ferrari-based thrill, Warner Bros’ exciting rides, SeaWorld’s unparalleled marine wonders, or Yas Waterworld’s exciting attractions, you can look forward to an action-and-excitement packed day."


            },
            {
                image: airDrop,
                day: "Day 6",
                title: "Airport Drop-Off",
                description: "A relaxed departure transfer in a spotless vehicle lets you wind up your holiday with zero stress."
            }
        ]
    },
    
        4: {
            title: "Honeymoon Bliss Dubai Tour Package",
            inclusionTitle: "Inclusions",
            heroImage: HoneymoonBliss,
            inclusions: [
                "Accommodation in Dubai",
                "Daily Breakfast",
                "Dubai City Tour",
                "Desert Safari with BBQ dinner",
                "Burj Khalifa tickets",
                "Dubai Mall & Fountain Show",
                "Dubai Frame entry",
                "One-hour private luxury yacht ride with refreshments",
                "One-hour private limousine ride",
                "Dhow Cruise at Dubai Marina with dinner",
                "Return Airport Transfers",
                "All taxes included except Tourism Dirham"
            ],
            itinerary: [
                {
                    image: arraival,
                    day: "Day 1",
                    title: "Airport Pick-Up",
                    description: "Give an unperturbed start to your romantic retreat as a vehicle with a driver whisks you to your pre-arranged Dubai accommodation. Relax in your room, or start your holiday right away by exploring the nearby attractions."
                },
                {
                    image: DesertSafari,
                    day: "Day 2",
                    title: "Dubai City Tour and Desert Safari",
                    description: "Discover Dubai’s blend of traditional and modern attractions on a half-day city tour. From Al Fahidi Historical Neighbourhood and Jumeirah Mosque to Burj Al Arab and Palm Jumeirah’s stunning sights, this guided excursion covers it all. An evening desert safari, with the right measure of adventure, culture, and nature, will surely enliven your day. Enjoy an exhilarating dune bash, embark on a camel ride, try Shisha smoking, sink your teeth into a rich, colorful BBQ buffet dinner, and watch live shows like Belly Dance and Tanura."
                },
                {
                    image: Yatch,
                    day: "Day 3",
                    title: "Dubai Frame and Private Luxury Yacht Ride with Refreshments",
                    description: "Spend the first part of the day witnessing Dubai’s old and new sights from one of the city’s iconic architectural landmarks, Dubai Frame. Reserve the second part for a private romantic yacht ride offering a cake-cutting celebration, washed down with unlimited refreshments."
                },
                {
                    image: BurjFoundation,
                    day: "Day 4",
                    title: "The Dubai Mall, Burj Khalifa, and the Musical Fountain Show",
                    description: "On the fourth day, visit Downtown Dubai’s superlative sights! Immerse yourself in the Dubai Mall’s unparalleled vibrancy, marvel at the world’s tallest building (Burj Khalifa) as you step into its 124th-level observation deck, and be in awe of the stunning musical fountain show outside the mall, all with your beloved by your side."
                },
                {
                    image: Lim,
                    day: "Day 5",
                    title: "Private Limousine Ride and Dhow Cruise at Dubai Marina",
                    description: "Take in Dubai’s extraordinary sights from the regal comforts of a luxurious limousine reserved for you and your significant other. Heighten your sightseeing adventure as you both next board a classic Arabian wooden dhow for a two-hour cruise along Dubai Marina. Exceptional views, with sumptuous buffet dining and live entertainment, will enrich the cruise."
                },
                {
                    image: airDrop,
                    day: "Day 6",
                    title: "Airport Drop-Off",
                    description: "Leave all the hassles of finding a cab behind as you check out of the hotel. A driver will be ready at the hotel’s lobby or desired spot to transfer you to the airport safely, ensuring you both have one of the most fantastic holidays of your lifetime."
                }
            ]
        }
    
};

export default tripDetails;
