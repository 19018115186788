import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeHeader from '../../components/comman/Header'; // Header for Home Page
import DefaultHeader from '../../components/comman/DefaultHeader'; // Header for Other Pages
import Footer from './Footer';

const MainLayout = ({ children }) => {
  const location = useLocation();

  // Check if the current path is home
  const isHomePage = location.pathname === '/';

  return (
    <div className="min-h-screen flex flex-col">
  {isHomePage ? <HomeHeader /> : <DefaultHeader />}
  <main className="flex-grow">{children}</main>
  <Footer />
</div>

  );
};

export default MainLayout;
