import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <div 
      className="py-16 px-6 lg:px-20 mt-[4rem] bg-cover bg-center"
      style={{ backgroundImage: "url('')" }} // Background Image
    >
      {/* Header Image */}
      {/* <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
        Contact Us
      </h2> */}
      <div className="text-center mb-8">
        <img 
          src="/assets/img/contact.jpg" // Change to your image
          alt="Contact Us"
          className="w-full h-[30rem]  object-cover mx-auto rounded-lg shadow-md"
        />
      </div>

    

      <div className="grid gap-8 md:grid-cols-2">
        {[
        {
          title: "Talk to a member of our UAE team",
          description:
            "Expert travel support and tailored tour packages from Arabian Kings Travel & Tourism. Let’s plan your journey!",
          buttonText: "Contact Sales",
          phone: ["+971 50 967 5843", "+971 50 757 5843"],
          email: "info@arabiankings.ae",
          address: "Damascus Street - Al Qusais Industrial Area 4, Dubai, UAE",
          image: "/sales-team.jpg", // Profile Image
        },
        {
          title: "Talk to a member of our Sales team",
          description:
            "Get the best deals and expert guidance on your next trip. Our team is here to help!",
          buttonText: "Go to the help center",
          phone: ["+91 6238 771 651"],
          email: "sales@arabiankings.ae",
          address:
            "Travel World, F3, Technomall, Techno Park Campus, TVM, Kerala",
          image: "/support-team.jpg", // Profile Image
        },
        
        ].map((item, index) => (
          <div
            key={index}
            className="bg-white p-10 rounded-xl shadow-lg text-center flex flex-col items-center"
          >
            
           

            <h3 className="text-xl font-semibold text-red-800 mb-3">
              {item.title}
            </h3>
            <p className="text-gray-600 mb-6">{item.description}</p>

            <div className="text-gray-700 text-lg space-y-2">
              <p className="flex items-center justify-center">
                <FaMapMarkerAlt className="mr-2 text-red-500" />
                {item.address}
              </p>
              {item.phone.map((num, i) => (
                <p key={i} className="flex items-center justify-center">
                  <FaPhoneAlt className="mr-2 text-green-500" />
                  {num}
                </p>
              ))}
              <p className="flex items-center justify-center">
                <FaEnvelope className="mr-2 text-blue-500" />
                {item.email}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
