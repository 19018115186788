import React, { useState, useEffect } from "react";
import PriceCard from "../../components/cards/PriceCard";
import { packageData } from "./Service";
import InnerPageCarousel from "../../components/carousel/InnerPageCarousel";

function ServicesOne() {
  const [view, setView] = useState(localStorage.getItem("view") || "grid");
  const [cardData, setCardData] = useState(packageData);
  const [sortType, setSortType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    price: null,
    category: "",
    nights: null,
  });

  useEffect(() => {
    localStorage.setItem("view", view);
  }, [view]);

  const handleSort = (type) => {
    let sortedData = [...cardData];
    if (type === "price") {
      sortedData.sort(
        (a, b) => parseFloat(a.discountedPrice) - parseFloat(b.discountedPrice)
      );
    } else if (type === "review") {
      sortedData.sort((a, b) => b.reviews - a.reviews);
    }
    setCardData(sortedData);
    setSortType(type);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const filteredData = cardData.filter((card) => {
    return (
      card.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filters.category ? card.category === filters.category : true) &&
      (filters.nights ? parseInt(card.nights) >= filters.nights : true)
    );
  });

  return (<>
    <InnerPageCarousel />
    <div className="container mx-auto p-4 ">
      {/* Responsive layout */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Filters Section */}
        <aside className="md:col-span-3 bg-[#2A2A2A] p-4 rounded-lg shadow-lg w-full">
          <h3 className="text-lg font-semibold mb-4 text-white">Filters</h3>
          <div className="mb-4">
            <label className="block mb-2 font-medium text-white">Category</label>
            <select
              className="border p-2 w-full rounded-md"
              onChange={(e) => handleFilterChange("category", e.target.value)}
              value={filters.category}
            >
              <option value="">All</option>
              <option value="Luxury">Luxury</option>
              <option value="Premium">Premium</option>
              <option value="Budget">Budget</option>
              <option value="Seasonal">Seasonal</option>
              <option value="Festive">Festive</option>
              <option value="Romantic">Romantic</option>
              <option value="Cultural">Cultural</option>
              <option value="Family">Family</option>
              <option value="Adventure">Adventure</option>
              <option value="Shopping">Shopping</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-medium text-white">
              Minimum Nights
            </label>
            <input
              type="number"
              className="border p-2 w-full rounded-md"
              placeholder="Enter nights"
              value={filters.nights || ""}
              onChange={(e) => handleFilterChange("nights", e.target.value)}
            />
          </div>
        </aside>

        {/* Main Content */}
        <main className="md:col-span-9 min-h-[calc(100vh-100px)]">
          {/* Sorting & Search */}
          <div className="flex flex-col md:flex-row justify-between items-center bg-[#fb4748] text-white p-4 rounded-lg mb-4 shadow-lg">
            <div className="text-lg font-semibold mb-3 md:mb-0">
              {filteredData.length} Dubai City Packages
            </div>
            <div className="flex flex-col md:flex-row gap-3 w-full md:w-auto">
              <select
                className="border p-2 rounded-md bg-white text-black"
                onChange={(e) => handleSort(e.target.value)}
                value={sortType}
              >
                <option value="">Sort by</option>
                <option value="price">Sort by Price</option>
                <option value="review">Sort by Review</option>
              </select>
              <input
                type="text"
                placeholder="Search by Package name"
                className="border p-2 rounded-md bg-white text-black w-full md:w-auto"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Package Cards Grid or No Results Message */}
          {filteredData.length > 0 ? (
            <div
              className={`grid ${view === "grid"
                  ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                  : "space-y-4"
                }`}
            >
              {filteredData.map((card, index) => (
                <PriceCard key={index} {...card} isListView={view === "list"} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 font-semibold text-lg mt-10">
              No results found
            </div>
          )}
        </main>
      </div>
    </div></>

  );
}

export default ServicesOne;
