export const packageData = [
  {
    id: 1,
    image: "/assets/img/pack1.jpg",
    title: "Beat the Heat in Dubai",
    nights: "4",
    reviews: "54",
    rating: (Math.random() * 5).toFixed(1),
    category: "Budget",
    discountedPrice: "On Request",
    buttonLink: `/services/service1/details/1`,
  },
  {
    id: 2,
    title: "Splendid Package",
    nights: "6",
    reviews: "78",
    rating: (Math.random() * 5).toFixed(1),
    category: "Premium",
    discountedPrice: "On Request",
    image: "/assets/img/splendid.jpg",
    buttonLink: `/services/service1/details/2`,
  },
  {
    id: 3,
    title: "Super Saver Dubai & Abu Dhabi Tour",
    nights: "6",
    reviews: "78",
    rating: (Math.random() * 5).toFixed(1),
    category: "Premium",
    discountedPrice: "On Request",
    image: "/assets/img/super-saver.png",
    buttonLink: `/services/service1/details/3`,
  },
  {
    id: 4,
    title: "Dubai Honeymoon Bliss",
    nights: "6",
    reviews: "78",
    rating: (Math.random() * 5).toFixed(1),
    category: "Romantic",
    discountedPrice: "On Request",
    image: "/assets/img/couples.jpeg",
    buttonLink: `/services/service1/details/4`,
  },
  
];
