import React from "react";
import { useParams } from "react-router-dom";
import tripDetailsData from "./deatils";

const PackageDetails = () => {
  const { id } = useParams();
  const tripDetails = tripDetailsData[id];

  if (!tripDetails) {
    return <p className="text-center text-red-500 text-xl font-semibold">Package not found!</p>;
  }

  return (
    <div className="lg:container lg:mx-auto lg:mt-[6.6rem] p-6">
      {/* Hero Section */}
      <div className="relative w-full h-[30rem] rounded-xl overflow-hidden shadow-lg">
        <img
          src={tripDetails?.heroImage}
          alt="Trip Image"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex items-end p-8">
          <h1 className="text-5xl font-bold text-white">{tripDetails.title}</h1>
        </div>
      </div>

      {/* Inclusion Section */}
      <div className="bg-white shadow-lg p-6 rounded-xl mt-10 border border-gray-200">
        <h2 className="text-3xl font-semibold mb-6 border-b pb-3 text-gray-800">
          {tripDetails.inclusionTitle}
        </h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 text-lg">
          {tripDetails.inclusions.map((item, index) => (
            <li key={index} className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg shadow-sm">
              <span className="text-green-600 text-xl">✅</span>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Itinerary Section */}
      <div className="mt-12 space-y-10">
        {tripDetails.itinerary.map((day, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-xl shadow-lg border border-gray-200 flex flex-col md:flex-row gap-6 items-center hover:shadow-2xl transition-all duration-300"
          >
            {/* Image on the left */}
            <div className="md:w-1/3 w-full overflow-hidden rounded-lg">
              <img
                src={day.image}
                alt=""
                className="w-full h-auto lg:h-[15rem] object-cover rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
              />
            </div>
            {/* Content on the right */}
            <div className="md:w-2/3 w-full space-y-3">
              <h2 className="text-xl text-red-500 font-semibold">{day.day}</h2>
              <h2 className="text-2xl font-bold text-gray-800">{day.title}</h2>
              <p className="text-gray-600 leading-relaxed">{day.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackageDetails;
