import React from 'react';
import PriceCard from '../../components/cards/PriceCard';
import { packageData } from "../../pages/Services/Service";

function Activities() {
  return (
    <div className="container mx-auto ">
      {/* Title Section */}
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Dubai Tour Packages</h1>
        <p className="text-gray-500 mt-2">Discover the best tour packages that Dubai has to offer!</p>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {packageData.slice(0, 4).map((card) => (
          <PriceCard key={card.id} {...card} />
        ))}
      </div>
    </div>
  );
}

export default Activities;
