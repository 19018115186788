import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const InnerPageCarousel = () => {
  // Get service name from URL
  const { serviceName } = useParams();

  // Define slides data for each service
  const slidesData = {
    service1: [
      {
        image: "/assets/img/package-1.png",
        heading: "Dubai  Tour Packages",
        subheading: "",
      },
      {
        image: "/assets/img/package-2.png",
        heading: "Dubai Tour Packages",
        subheading: "",
      },
    ],
    service2: [
      {
        image: "/assets/img/service2-1.jpg",
        heading: "Service 2 - Slide 1",
        subheading: "Discover the advantages of Service 2",
      },
      {
        image: "/assets/img/service2-2.jpg",
        heading: "Service 2 - Slide 2",
        subheading: "Innovative solutions with Service 2",
      },
    ],
    // Add other services here...
  };

  // Get slides for the current service, or show default if not found
  const slides = slidesData[serviceName] || slidesData.service1;

  // State for current slide index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Autoplay state
  const [isAutoplay, setIsAutoplay] = useState(true);

  // Go to next slide
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  // Go to previous slide
  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  // Autoplay effect
  useEffect(() => {
    if (isAutoplay) {
      const timer = setInterval(goToNextSlide, 8000);
      return () => clearInterval(timer);
    }
  }, [isAutoplay]);

  return (
    <div className="relative w-full lg:h-[80vh] h-screen overflow-hidden">
      {/* Slide Display */}
      <div className="relative w-full h-full">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute w-full h-full bg-cover bg-center transition-opacity duration-1000 ease-in-out ${
              currentIndex === index ? "opacity-100" : "opacity-0"
            }`}
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          </div>
        ))}

        {/* Centralized Content */}
        <div className="relative z-10 flex lg:items-center items-end lg:justify-start justify-center pb-5 lg:pb-0 w-full h-full text-white text-center px-4">
          <div className="lg:px-20 lg:w-6/12 leading-[4rem] tracking-wide">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">{slides[currentIndex].heading}</h1>
            <p className="text-lg md:text-xl mb-6">{slides[currentIndex].subheading}</p>
          </div>
        </div>
      </div>

      {/* Navigation Arrows */}
      <button
        onClick={goToPreviousSlide}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-3xl p-2"
      >
        &#8249;
      </button>
      <button
        onClick={goToNextSlide}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white text-3xl p-2"
      >
        &#8250;
      </button>

      {/* Slide Indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              currentIndex === index ? "bg-white" : "bg-gray-500"
            }`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default InnerPageCarousel;
